@use 'src/src/app/scss/abstracts' as *;

.header_logo {
  flex: 0 0 auto;

  &__link {
    display: inline-flex;
    position: relative;

    img {
      @include mq($until: lg) {
        width: 89px;
        height: 22px;
      }
    }
  }
}
